import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./style.scss";
import Footer from "../../layouts/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../assets/context/userContext";
import back from "./img/back.png";
import farmericon from "./img/farmericon.png";
import locationicon from "./img/locationicon.gif";
import Loading from "../../layouts/Loading/Loading";
import { ShoppingContext } from "../../assets/context/shoppingContext";
import { BackendUrlContext } from "../../assets/context/backendUrlContext";

const ProductDetailsPage = () => {
  const [productData, setProductData] = useState({});
  const { baseUrl, imgUrl } = useContext(BackendUrlContext);
  const navigate = useNavigate();
  const productID = useParams();
  const { shoppingItems, setShoppingItems, products, loading, setLoading } =
    useContext(ShoppingContext);
  const { user } = useContext(UserContext);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/products/id/${productID.productID}`)
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productID]);
  const isInBasket = shoppingItems.some((item) => item._id === productData._id);

  const handleAddToBasket = () => {
    if (!shoppingItems.find((item) => item._id === productData._id)) {
      setShoppingItems((prevItems) => [...prevItems, productData]);
    }
  };
  const handleMapLinkClick = (searchText) => {
    const encodedSearchText = encodeURIComponent(searchText);
    const googleMapsURL = `https://www.google.com/maps/search/${encodedSearchText}`;
    window.open(googleMapsURL, "_blank");
  };
  if (!loading) {
    return (
      <>
        <div className="product">
          <div className="product-details">
            <div className="product-image">
              <img
                src={`${imgUrl}${productData?.productimage}`}
                alt={productData.productname}
              />
            </div>
            <div className="product-info">
              <div
                className="product-info-back"
                onClick={() => {
                  navigate("/products");
                }}
              >
                <img src={back} alt="<--" />
                <p>Back to Products</p>
              </div>
              <div className="product-info-head">
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/products/search/${productData.productcategory}`);
                  }}
                >
                  Category: {productData.productcategory}
                </p>
                <h2>{productData.productname}</h2>
              </div>
              <div className="product-info-quality">
                <p>Status:</p>
                <div 
                className="product-info-quality-div"
                  style={{
                    cursor: "pointer", display:"flex", flexDirection:"row" ,width:"fit-content", flexWrap:"wrap",
                    color: "#8400A2",
                  }}
                >
                  <img
                    src={locationicon}
                    alt=""
                    width={"50px"}
                    height={"50px"}
                  />
                  <p style={{margin:0}}>{productData.producttype}</p>
                </div>
              </div>
              <div className="product-info-details">
                <div className="product-info-details-general">
                  <div
                  className="product-info-details-general-div"
                    style={{ cursor: "pointer", display:"flex", flexDirection:"row",width:"fit-content", flexWrap:"wrap", }}
                    onClick={() => {
                      navigate(`/products/search/${productData.fullname}`);
                    }}
                  >
                    <img
                      src={farmericon}
                      alt=""
                      width={"50px"}
                      height={"50px"}
                    />
                    <p style={{margin:0}}>{productData.fullname}</p>
                  </div>
                </div>
                <div
                  className="product-info-details-description"
                  style={{ backgroundColor: "#fcfcfc" }}
                >
                  <div>{productData.productdescription}</div>
                </div>
              </div>
            </div>
            <div className="product-order">
              <div className="product-order-detail">
                <div className="product-order-detail-l">
                  <div className="product-order-detail-l-img">
                    <img
                      src={`${imgUrl}${productData?.productimage}`}
                      alt=""
                    />
                  </div>
                  <div className="product-order-detail-l-name">
                    <div className="product-order-detail-l-name-name">
                      {productData.productname}
                    </div>
                    <div className="product-order-detail-l-name-region">
                      {productData.region}
                    </div>
                  </div>
                </div>
                <div className="product-order-detail-r">
                  {/* <div className="product-order-detail-r-price">
                    {productData.productprice} {productData.productunit}
                  </div> */}
                  <select name="" className="product-order-detail-r-price">
                    <option value="AZN">{productData?.productprice} ₼</option>
                    <option value="USD">{(productData?.productprice *0.59).toFixed(0)} $ </option>
                    <option value="EURO">{(productData?.productprice *0.53).toFixed(0)} €</option>
                  </select>
                  {user && user._id === productData.userid ? (
                    <div className="product-order-detail-r-add">
                      <div
                        className="product-order-detail-r-addbasket"
                        style={{ backgroundColor: "#7F7F7F" }}
                      >
                        It's your Product
                      </div>
                    </div>
                  ) : (
                    <div className="product-order-detail-r-add">
                      <div
                        className="product-order-detail-r-addbasket"
                        onClick={handleAddToBasket}
                        style={{
                          backgroundColor: isInBasket ? "#00ac22" : "#000ca8",
                        }}
                      >
                        {isInBasket ? "Already in Basket" : "Add to Basket"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="product-similar">
            <div className="product-similar-name">Similar Products</div>
            {products
              .filter((item) => item.productcategory === productData.productcategory)
              .slice(0, 5)
              .map((product, index) => (
                <div
                  className="product-similar-detail"
                  key={index}
                  onClick={() => {
                    navigate(`/products/id/${product._id}`);
                  }}
                >
                  <div className="product-similar-detail-l">
                    <div className="product-similar-detail-l-img">
                      <img
                        src={`${imgUrl}${product?.productimage}`}
                        alt=""
                      />
                    </div>
                    <div className="product-similar-detail-l-name">
                      <div className="product-similar-detail-l-name-name">
                        {product.productname}
                      </div>
                      <div className="product-similar-detail-l-name-region">
                        {product.productdescription}
                      </div>
                    </div>
                  </div>
                  <div className="product-similar-detail-r">
                    <div className="product-similar-detail-r-price">
                      {product.productprice} {product.productunit}
                      
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default ProductDetailsPage;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import axios from "axios";
import toast from "react-hot-toast";

const SignupPage = () => {
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState({
    fullname: "",
    number: "",
    email: "",
    password: "",
  });

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("user/register", {
        fullname: registerData.fullname,
        number: registerData.number,
        email: registerData.email.toLowerCase(),
        password: registerData.password,
      });

      if (data.error) {
        toast.error(data.error);
      } else {
        setRegisterData({
          fullname: "",
          number: "",
          email: "",
          password: "",
        });
        toast.success("Registration successful");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="Signup">
      <div className="d-flex justify-content-center align-items-center vh-100 opacity-80 Signup-in">
        <div className="bg-white p-3 rounded Signup-in-in " >
          <form onSubmit={registerUser} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="name">
                <strong>Full Name</strong>
              </label>
              <input
                type="text"
                placeholder="Enter FullName"
                className="form-control rounded"
                name="fullname"
                value={registerData.fullname}
                onChange={(e) => {
                  setRegisterData({
                    ...registerData,
                    fullname: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                <strong>Phone Number(xx-xxx-xx-xx)</strong>
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  +994
                </span>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="form-control"
                  placeholder="Enter phone number"
                  pattern="[0-9]{9}"
                  value={registerData.number}
                  onChange={(e) => {
                    setRegisterData({
                      ...registerData,
                      number: e.target.value,
                    });
                  }}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="email">
                <strong>Email</strong>
              </label>
              <input
                type="email"
                placeholder="Enter Email"
                className="form-control rounded"
                name="email"
                value={registerData.email}
                onChange={(e) => {
                  setRegisterData({ ...registerData, email: e.target.value.toLowerCase() });
                }}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password">
                <strong>Password</strong>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                className="form-control rounded"
                name="password"
                value={registerData.password}
                onChange={(e) => {
                  setRegisterData({
                    ...registerData,
                    password: e.target.value,
                  });
                }}
                required
              />
            </div>
            <button className="btn btn-success w-100">
              <strong>Create Account</strong>
            </button>
            <p>You are agree to our policies and terms</p>
            <Link to="/login" className="btn btn-default border w-100 bg-light">
              Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;

import React from 'react'
import loading from "./fruitsloading.gif";
const ProductLoading = () => {
  return (
    <div style={{width:"100%", height:"100vh", objectFit:"contain", display:"flex", alignItems:"center", justifyContent:"center", overflow:"hidden"}}>
        <img src={loading} alt="" />
    </div>
  )
}

export default ProductLoading
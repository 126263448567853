import React, { useContext, useEffect } from "react";
import "./style.scss";
import infoImg from "./img/info-img.png";
import box from "./img/box.svg";
import delivery from "./img/delivery.svg";
import farm from "./img/farm.svg";
import arrowSVG from "./img/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../../layouts/Loading/Loading";
import { ShoppingContext } from "../../assets/context/shoppingContext";

const OpenAnswer = (id) => {
  if (document.getElementById(id).style.display === "none") {
    document.getElementById(id).style.display = "block";
  } else {
    document.getElementById(id).style.display = "none";
  }
};

const AboutPage = () => {
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(ShoppingContext);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const Advantages = [
    {
      svg: delivery,
      alt: "delivery",
      name: "Vision",
      description:
        "Featuring a diverse ensemble of talented artists, DALI art gallery is proud to showcase the work of both emerging talents and established visionaries.",
    },
    {
      svg: farm,
      alt: "farm",
      name: "Explore",
      description:
        "Step into the world of inspiration and imagination with our selected artworks in DALI art gallery.",
    },
    {
      svg: box,
      alt: "box",
      name: "Delivery",
      description:
        "We want our client to receive their artworks as soon as possible, so we process and ship the order at once.",
    },
  ];
  const AllQuestionsAndAnswers = [
    {
      answerID: 1,
      arrow: arrowSVG,
      question: "How many days is the ordered product delivered?",
      answers:
        "Your orders will be packed and shipped within 1 business day. As the delivery to the regions will be by mail, the time of arrival to the regions may be a little late.",
    },
    {
      answerID: 2,
      arrow: arrowSVG,
      question: "How will the delivery be?",
      answers:
        "Delivery will be in roll form. If artwork is requested to be delivered framed or unframed, you will be charged an additional delivery fee. This is because additional packaging and different shipping is needed for the safety of the artworks.",
    },
    {
      answerID: 3,
      arrow: arrowSVG,
      question: "Why do we need to enter our personal information?",
      answers:
        "Your personal information, primarily name, surname, ID card's FIN code or serial number is to ensure that the right person is handed over when handing over artworks. At the time of delivery, we will check the ID card. If the personal information given to us does not match the information on the ID card, the sale will be canceled and the artwork will be returned.",
    },
    {
      answerID: 4,
      arrow: arrowSVG,
      question: "Where do you get the products?",
      answers:
        "We try to get artworks from all Azerbaijani artists as much as possible. And we post each artwork on the site after we personally go and review the quality for our customers' satisfaction.",
    },
    {
      answerID: 5,
      arrow: arrowSVG,
      question: "How is the payment made?",
      answers:
        "Payment will be made in cash at first, but will be transferred to card payment as soon as possible.",
    },
  ];
  if (!loading) {
    return (
      <div className="AboutPage">
        <div className="Ab">
          <div className="About">
            <div className="About-Advantages">
              {Advantages.map((item, index) => {
                return (
                  <div key={index} className="About-Advantages-Item">
                    <div className="About-Advantages-Item-img">
                      <img src={item.svg} alt={item.alt} />
                    </div>
                    <h1 className="About-Advantages-Item-name">{item.name}</h1>
                    <h5 className="About-Advantages-Item-description">
                      {item.description}
                    </h5>
                  </div>
                );
              })}
            </div>

            <div className="About-Info">
              <div className="About-Info-Text">
                <div className="About-Info-Text-name">About us</div>
                <div className="About-Info-Text-description">
                  Welcome to DALI art gallery, a captivating space where art
                  comes to life. Our gallery is a testament to creativity,
                  showcasing a diverse range of art that promises to inspire and
                  engage. Step into a world where every brushstroke tells a
                  story, and each piece invites you to explore the boundless
                  realms of artistic expression. Join us on a journey through
                  the extraordinary as we celebrate the power and beauty of
                  visual art at DALI art gallery.
                </div>
              </div>
              <div className="About-Info-img">
                <img src={infoImg} alt="DALI" />
              </div>
            </div>
          </div>
        </div>
        <div className="Que">
          <div className="Questions">
            <div className="Questions-name">Frequently asked questions</div>
            {AllQuestionsAndAnswers.map((item) => {
              return (
                <div
                  className="Questions-QA"
                  key={item.answerID}
                  onClick={() => OpenAnswer(item.answerID)}
                >
                  <div className="Questions-QA-Q">
                    {item.question}{" "}
                    <img
                      src={item.arrow}
                      alt="&#8964;"
                      className="Questions-QA-Q-svg"
                    />
                  </div>
                  <div className="Questions-QA-A" id={item.answerID}>
                    {item.answers}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default AboutPage;

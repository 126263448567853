import React, { useEffect, useState } from "react";
import "./style.scss";
import { useContext } from "react";
import { ShoppingContext } from "../../assets/context/shoppingContext";
import x from "./img/x.svg";
import plus from "./img/+.png";
import minus from "./img/-.png";
import emptybasket from "./img/emptybasket.jpg";
import deleteicon from "./img/delete.svg";
import { TotalPriceContext } from "../../assets/context/TotalPriceContext";
import Shopform from "../ShopForm/Shopform";
import { ShoppingFormContext } from "../../assets/context/shopFormContext";
import { BackendUrlContext } from "../../assets/context/backendUrlContext";
import { Alert } from "@mui/material";
import axios from "axios";
const Shopping = () => {
  const { openShopping, setOpenShopping, shoppingItems, setShoppingItems } =
    useContext(ShoppingContext);
  const { totalPriceCont, setTotalPriceCont } = useContext(TotalPriceContext);
  const { shopForm, setShopForm } = useContext(ShoppingFormContext);
  const { baseUrl,imgUrl } = useContext(BackendUrlContext);
  const [openShopForm, setOpenShopForm] = useState(false);
  const handleRemoveProduct = (productId) => {
    const updatedShoppingItems = shoppingItems.filter(
      (item) => item._id !== productId
    );
    const updatedUpdateCount = { ...updateCount };
    delete updatedUpdateCount[productId];
    setUpdateCount(updatedUpdateCount);

    setShoppingItems(updatedShoppingItems);
    localStorage.setItem("shoppingItems", JSON.stringify(updatedShoppingItems));
    localStorage.setItem("updateCount", JSON.stringify(updatedUpdateCount));
  };

  ///////////////////////// local storage
  const [updateCount, setUpdateCount] = useState(() => {
    const storedCount = localStorage.getItem("updateCount");
    if (storedCount) {
      return JSON.parse(storedCount);
    }
    return {};
  });

  useEffect(() => {
    localStorage.setItem("updateCount", JSON.stringify(updateCount));
  }, [updateCount]);
  useEffect(() => {
    localStorage.setItem("shoppingItems", JSON.stringify(shoppingItems));
  }, [shoppingItems]);
  ////////////////////////
  const totalPrice = shoppingItems
    .slice(1)
    .reduce(
      (sum, product) =>
        sum +
        parseFloat(product.productprice) * (updateCount[product._id] || 1),
      0
    )
    .toFixed(2);

  const handleShoppingClick = async () => {
    setTotalPriceCont(totalPrice);
    if (
      shopForm.Name &&
      shopForm.Surname &&
      shopForm.Email &&
      shopForm.IDCardNumber &&
      shopForm.Number &&
      shopForm.Country &&
      shopForm.City &&
      shopForm.Street &&
      shopForm.ZipCode
    ) {
      const messageBody = `
        Name: ${shopForm.Name}
        Surname: ${shopForm.Surname}
        Email: ${shopForm.Email}
        ID Card Number: ${shopForm.IDCardNumber}
        Number: ${shopForm.Number}
        Country: ${shopForm.Country}
        City: ${shopForm.City}
        Street: ${shopForm.Street}
        Zip Code: ${shopForm.ZipCode}
        Total Price: ${totalPrice} AZN
      `;
    
      const phoneNumber = "994993040027";
      window.open(
        `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(
          messageBody
        )}`,
        "_blank"
      );
      await axios
      .post("/auth/sendEmail", {messageBody});
    }
  };
  return openShopping ? (
    <div className="Shopping">
      {!openShopForm ? (
        <>
          <div className="Shopping-Name">
            <div className="Shopping-Name-Name">
              <div className="Shopping-Name-Name-main">Shopping bag</div>
              <div className="Shopping-Name-Name-count">
                {shoppingItems.length - 1 > 0 ? (
                  <>({shoppingItems.length - 1} items)</>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="Shopping-Name-X">
              <img
                src={x}
                alt="x"
                onClick={() => {
                  setOpenShopping(false);
                }}
              />
            </div>
          </div>
          <div className="Shopping-Detail">
            {shoppingItems.length > 1 ? (
              <>
                <div className="Shopping-Detail-Cards">
                  {shoppingItems?.map((product, index) =>
                    product._id ? (
                      <div className="Shopping-Detail-Cards-Card" key={index}>
                        <div className="Shopping-Detail-Cards-Card-Img">
                          <img
                            src={`${imgUrl}/${product.productimage}`}
                            alt={product.productname}
                          />
                        </div>
                        <div className="Shopping-Detail-Cards-Card-About">
                          <div className="Shopping-Detail-Cards-Card-About-Product">
                            <div className="Shopping-Detail-Cards-Card-About-Product-name">
                              {product?.productname}
                            </div>
                            <div className="Shopping-Detail-Cards-Card-About-Product-price">
                              {product?.productprice} {product?.productunit}
                            </div>
                          </div>
                          <div className="Shopping-Detail-Cards-Card-About-Detail">
                            <div className="Shopping-Detail-Cards-Card-About-Detail-name">
                              {product?.fullname}
                            </div>
                            <div className="Shopping-Detail-Cards-Card-About-Detail-CountDel">
                              <div className="Shopping-Detail-Cards-Card-About-Detail-CountDel-count">
                                <div className="Shopping-Detail-Cards-Card-About-Detail-CountDel-count-m">
                                  -
                                </div>
                                <div className="Shopping-Detail-Cards-Card-About-Detail-CountDel-count-n">
                                  {updateCount[product._id] || 1}
                                </div>
                                <div className="Shopping-Detail-Cards-Card-About-Detail-CountDel-count-p">
                                  +
                                </div>
                              </div>
                              <div
                                className="Shopping-Detail-Cards-Card-About-Detail-CountDel-delete"
                                onClick={() => handleRemoveProduct(product._id)}
                              >
                                <img src={deleteicon} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                </div>

                <div className="Shopping-Detail-Button">
                  <div
                    className="Shopping-Detail-Button-AddDetail"
                    onClick={() => {
                      setOpenShopForm(!openShopForm);
                    }}
                  >
                    Add your Detail
                  </div>
                </div>
              </>
            ) : (
              <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                <img className="w-100 h-100" src={emptybasket} alt="Empty" />
                <h1>Your basket is empty</h1>
              </div> //shoppinglength
            )}
          </div>
        </>
      ) : (
        <>
          <Shopform setOpenShopForm={setOpenShopForm} />
          {shopForm.Name &&
            shopForm.Surname &&
            shopForm.Email &&
            shopForm.IDCardNumber &&
            shopForm.Number &&
            shopForm.Country &&
            shopForm.City &&
            shopForm.Street &&
            shopForm.ZipCode? (
              <div
                className="Shopping-Detail-Button-Checkout"
                onClick={handleShoppingClick}
              >
                Check Out - {totalPrice} AZN
              </div>
            ):<div
            className="Shopping-Detail-Button-Checkout-not"
            onClick={()=>{alert("Please fill all lines")}}
          >
            Check Out - {totalPrice} AZN
          </div>}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Shopping;

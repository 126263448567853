import axios from "axios";
import { createContext, useState } from "react";

export const CurrencyLanguageContext = createContext({});

export function CurrencyLanguageContextProvider({ children }) {

  const [currency, setCurrency] = useState("AZN");
  return (
    <CurrencyLanguageContext.Provider
      value={{ currency, setCurrency }}
    >
      {children}
    </CurrencyLanguageContext.Provider>
  );
}

import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Footer from "../../layouts/Footer/Footer";
import moment from "moment";
import addicon from "./img/add-icon.png";
import minusicon from "./img/added-icon.png";
import notaddedicon from "./img/notaddedicon.png";
import defaultProduct from "./img/default_1.jpg";
import { UserContext } from "../../assets/context/userContext";
import { ShoppingContext } from "../../assets/context/shoppingContext";
import { useNavigate, useParams } from "react-router-dom";
import { BlendingContext } from "../../assets/context/blendContext";
import FilterProducts from "../../layouts/FilterProducts/FilterProducts";
import ProductLoading from "../../layouts/Loading/ProductLoading";
import { BackendUrlContext } from "../../assets/context/backendUrlContext";
const ProductsPage = () => {
  let totalProductsLength = 0;
  const { user } = useContext(UserContext);
  const { shoppingItems, setShoppingItems, products, loading, setLoading } =
    useContext(ShoppingContext);
  const { setBlending } = useContext(BlendingContext);
  const { baseUrl, imgUrl } = useContext(BackendUrlContext);
  const navigate = useNavigate();
  const params = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [OnlySearchFilteredOrAllProducts, setOnlySearchFilteredOrAllProducts] =
    useState([]);
  const AddShoppingContext = (product) => {
    const updatedShoppingItems = [...shoppingItems];
    const index = updatedShoppingItems.findIndex(
      (item) => item._id === product._id
    );
    if (index !== -1) {
      updatedShoppingItems.splice(index, 1);
    } else {
      updatedShoppingItems.push(product);
    }

    setShoppingItems(updatedShoppingItems);
  };
  useEffect(() => {
    if (params.searchText) {
      const filtered = products.filter(
        (product) =>
          (product.productname &&
            product.productname
              .toLowerCase()
              .includes(params.searchText.toLowerCase())) ||
          (product.city &&
            product.city
              .toLowerCase()
              .includes(params.searchText.toLowerCase())) ||
          (product.fullname &&
            product.fullname
              .toLowerCase()
              .includes(params.searchText.toLowerCase())) ||
          (product.productcategory &&
            product.productcategory
              .toLowerCase()
              .includes(params.searchText.toLowerCase())) ||
          (product.producttype &&
            product.producttype
              .toLowerCase()
              .includes(params.searchText.toLowerCase()))
      );
      setFilteredProducts(filtered);
      setOnlySearchFilteredOrAllProducts(filtered);
    } else {
      setFilteredProducts(products);
      setOnlySearchFilteredOrAllProducts(products);
    }
  }, [params, products]);
  if (!loading) {
    return (
      <>
        <FilterProducts
          OnlySearchFilteredOrAllProducts={OnlySearchFilteredOrAllProducts}
          filteredProducts={filteredProducts}
          setFilteredProducts={setFilteredProducts}
        />
        <div
          className="Products"
          onClick={() => {
            setBlending(false);
          }}
        >
          {filteredProducts.map((product) => (
            <div
              className={`Products-Product ${
                totalProductsLength++ && totalProductsLength % 2 == 0 ? "validProduct" : "invalidProduct"}`}
              key={product._id}
            >
              <div className="Products-Product-TypeLike">
                <div className="Products-Product-TypeLike-Type">
                  {totalProductsLength % 2 == 0 ? (
                    <div className="Products-Product-TypeLike-Type-Fresh">
                      {product.producttype}
                    </div>
                  ) : (
                    <div className="Products-Product-TypeLike-Type-NotFresh">
                      {product.producttype}
                    </div>
                  )}
                </div>
                <div className="Products-Product-TypeLike-Like">
                  {moment(product.createdAt).fromNow()}
                </div>
              </div>
              <div
                className="Products-Product-Photo"
                onClick={() => {
                  navigate(`/products/id/${product._id}`);
                }}
              >
                {product?.productimage ? (
                  <img
                    src={`${imgUrl}${product?.productimage}`}
                    alt={product.productname}
                  />
                ) : (
                  <img src={defaultProduct} />
                )}
              </div>
              <div className="Products-Product-About">
                <div className="Products-Product-About-S">
                  <span className="Products-Product-About-S-Name">
                    {product?.productname}
                  </span>
                  {/* <div className="Products-Product-About-S-City">
                    
                  </div> */}
                  {/* <div className="Products-Product-About-S-Price">
                    {product?.productprice} {product.productunit}
                  </div> */}
                  <select name="Price" className="Products-Product-About-S-Price" defaultValue={"AZN"}>
                    <option value="AZN">{product?.productprice} ₼</option>
                    <option value="USD">{(product?.productprice *0.59).toFixed(0)} $ </option>
                    <option value="EURO">{(product?.productprice *0.53).toFixed(0)} €</option>
                  </select>
                </div>
                {user && user._id === product.userid ? (
                  <div className="Products-Product-About-L">
                    <img src={notaddedicon} alt="Own" />
                  </div>
                ) : (
                  <div
                    className="Products-Product-About-L"
                    onClick={() => {
                      AddShoppingContext(product);
                    }}
                  >
                    {shoppingItems.some((item) => item._id === product._id) ? (
                      <img src={minusicon} alt="-" />
                    ) : (
                      <img src={addicon} alt="+" />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
          {!totalProductsLength && <div className="Products-totalSizeNot" onClick={() => {
                   window.location.reload();
                }}>Please reload the page</div>}
          <div className="Products-totalSize"><span>Total: {totalProductsLength} products</span></div>
        </div>
        <Footer />
      </>
    );
  } else {
    return <ProductLoading />;
  }
};
export default ProductsPage;

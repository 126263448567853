import { createContext, useState } from "react";

export const BackendUrlContext = createContext({});

export function BackendUrlContextProvider({ children }) {

  const [baseUrl, setbaseUrl] = useState("https://dali-az-api.vercel.app");
  // const [baseUrl, setbaseUrl] = useState("http://localhost:5000/");
  const [imgUrl, setImgUrl] = useState("https://raw.githubusercontent.com/nihadishi/dali-img/main/")
  return (
    <BackendUrlContext.Provider
      value={{ baseUrl,imgUrl }}
    >
      {children}
    </BackendUrlContext.Provider>
  );
}
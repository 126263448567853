import axios from "axios";
import { createContext, useState, useEffect } from "react";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {

const initialState = null;
const [user, setUser] = useState(initialState);

  useEffect( () => {
        axios.get('user/profile')
            .then(({data})=>{
                setUser(data)
            }).catch(error =>{}
             )
  }, []);
  useEffect(  () => {
    if(!user){
         axios.get('user/profile')
            .then(({data})=>{
                setUser(data)
            }).catch(error =>{}
             )
      }
  }, [user]);
  return (
    <UserContext.Provider value={{ user, setUser, }}>
      {children}
    </UserContext.Provider>
  );
}

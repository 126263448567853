import React from "react";
import "./style.scss";
import instagram from "./img/instagram.svg";
import tiktok from "./img/tiktok.svg";
import youtube from "./img/youtube.png";
const Footer = () => {
  return (
    <div className="Foo">
      <div className="Footer">
        <div className="Footer-Contact">
          <div className="Footer-Contact-Name">Contact:</div>
          <div className="Footer-Contact-Left">
            <h3>Email: <a href="mailto:dreamartlandmmc@gmail.com" style={{textDecoration:"none"}}> dreamartlandmmc@gmail.com</a></h3>
            <h3>Phone Number: <a href="tel:+994993040027" style={{textDecoration:"none"}}>+994-99-304-00-27</a></h3>
          </div>
          <div className="Footer-Contact-Right">
            <h3>
              <a href="#" target="_blank" rel="noopener noreferrer">
                Privacy policies and terms{" "}
              </a>
            </h3>
          </div>
        </div>
        <div className="Footer-Copyright">
          <div className="Footer-Copyright-Social">
            <div className="Footer-Copyright-Social-Imgs">
              <a href="https://www.instagram.com/dali.azerbaijan/" target="_blank">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="https://www.tiktok.com/@dali.azerbaijan" target="_blank">
                <img src={tiktok} alt="tiktok" />
              </a>
            </div>
          </div>
          <div className="Footer-Copyright-Copyright">
            <div className="Footer-Copyright-Copyright-title">
              {" "}
              © Powered by
            </div>
            <div>&nbsp;</div>
            <div className="Footer-Copyright-Copyright-name">
              {" "}
              DALI MMC 2023{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

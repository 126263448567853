import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../layouts/Footer/Footer";
import "./style.scss";
import slide1 from "./img/1.jpg";
import slide2 from "./img/2.jpg";
import slide3 from "./img/3.jpg";
import slide4 from "./img/4.jpg";
import slide5 from "./img/5.jpg";
import slide6 from "./img/6.jpg";
import slide7 from "./img/7.jpg";
import slide8 from "./img/8.jpg";
import slide9 from "./img/9.jpg";
import slide10 from "./img/10.jpg";
import slide11 from "./img/11.jpg";
import slide12 from "./img/12.jpg";
import slide13 from "./img/13.jpg";
import bg_M from "./img/bg1.png";
import Loading from "../../layouts/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { ShoppingContext } from "../../assets/context/shoppingContext";
const HomePage = () => {
  const { loading, setLoading } = useContext(ShoppingContext);
  const [backgroundImage, setBackgroundImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const newImageIndex = (backgroundImage + 1) % 13;
      setBackgroundImage(newImageIndex);
    }, 1500);

    return () => clearInterval(interval);
  }, [backgroundImage]);

  const backgroundImages = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
    slide13,
  ];
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2800);

    return () => clearTimeout(timeout);
  }, []);
  if (!loading) {
    return (
      <>
        <div className="Home">
          <div className="Int">
            <div className="Int-M">
              <img src={bg_M} alt="" />
            </div>
            <div className="Intro">
              <div className="Intro-About">
                <div className="Intro-About-Text">
                  Join us in celebrating the power of artistic expression
                  at our gallery
                </div>
                <div className="Intro-About-Order">
                  <button
                    className="Intro-About-Order-button"
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    Order now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sections">
            <section
              className="hero"
              style={{
                backgroundImage: `url(${backgroundImages[backgroundImage]})`,
              }}
            >
              <h1>Welcome to Our Website</h1>
              <p>
                Your one-stop destination for amazing products and services.
              </p>
              <a
                className="cta-button"
                onClick={() => {
                  navigate("/about");
                }}
              >
                Learn More
              </a>
            </section>

            <section className="features">
              <div className="feature">
                <h2>Gallery products</h2>
                <p>
                  Explore a captivating collection of artistic masterpieces at
                  DALI art gallery, where paintings come to life with vibrant
                  colors. Immerse yourself in a world where creativity
                  knows no bounds.
                </p>
              </div>
              <div className="feature">
                <h2>High quality</h2>
                <p>
                  At DALI art gallery, we pride ourselves on delivering art of
                  the highest quality. Each piece is a testament to meticulous
                  craftsmanship, ensuring that our patrons experience the finest
                  standards in artistic excellence.
                </p>
              </div>
              <div className="feature">
                <h2>Free delivery</h2>
                <p>
                  Enjoy the added convenience of free delivery on all our
                  exquisite art pieces at DALI art gallery. Elevate your space
                  with ease as we bring the beauty of our collection directly to
                  your doorstep, without any additional cost.
                </p>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default HomePage;

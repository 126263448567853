import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import "bootstrap/dist/css/bootstrap.min.css";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    <React.StrictMode>
      <BrowserRouter>
        <App />
      <SpeedInsights/>
      <Analytics />
      </BrowserRouter>
    </React.StrictMode>
    
);
reportWebVitals();

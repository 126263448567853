import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { UserContext } from "../../assets/context/userContext";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ShoppingContext } from "../../assets/context/shoppingContext";

const MultiStepForm = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const {loading , setLoading} = useContext(ShoppingContext);
  const [step, setStep] = useState(1);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [addproductdata, setaddproductData] = useState({
    productimage: "",
    productname: "",
    productdescription: "",
    productprice: "",
    productunit: "",
    productcategory: "",
    producttype: "",
    fullname: user.fullname,
    number: user.number,
    email: user.email,
    id: user._id,
  });
  console.log(addproductdata);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 400);
  });
  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  const handleCancel = () => {
    setIsFormOpen(false);
  };





  const addProduct = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("products/add", {
        productimage: addproductdata.productimage,
        productname: addproductdata.productname,
        productdescription: addproductdata.productdescription,
        productprice: addproductdata.productprice,
        productunit: addproductdata.productunit,
        productcategory: addproductdata.productcategory,
        producttype: addproductdata.producttype,
        fullname: addproductdata.fullname,
        number: addproductdata.number,
        email: addproductdata.email,
        userid: addproductdata.id,
      });

      if (data.error) {
        console.log(data);
        toast.error("Can't add the product, please try again");
      } else {
        setaddproductData({
          productimage: "",
          productname: "",
          productdescription: "",
          productprice: "",
          productunit: "",
          productcategory: "",
          producttype: "",
          fullname: user.fullname,
          number: user.number,
          email: user.email,
          id: user._id,
        });
        toast.success("Product added");
        navigate("/products");
      }
    } catch (error) {
      toast.error("An error occurred while processing the image");
      console.error(error);
    }
  };
  return (
    <>
      {isFormOpen ? (
        <>
          <div className="Addproduct">
            <div className="multi-step-form">
              <h1>New product form</h1>
              <form encType="multipart/form-data" onSubmit={addProduct}>
                {step === 1 && (
                  <div className="step">
                    <h2>Step 1: Add Product Details</h2>

                    <label>Product Photo</label>
                    <input
                      type="text"
                      placeholder="Photo path"
                      className="Addproduct--text"
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productimage: e.target.value,
                        })
                      }
                      required
                    />

                    <label>Product Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      className="Addproduct--text"
                      value={addproductdata.productname}
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productname: e.target.value,
                        })
                      }
                      required
                    />
                    <label>Product Description</label>
                    <textarea
                      placeholder="Description"
                      className="Addproduct--text"
                      value={addproductdata.productdescription}
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productdescription: e.target.value,
                        })
                      }
                      required
                    />

                    <label>Product Price</label>
                    <input
                      type="number"
                      placeholder="Price (AZN)"
                      value={addproductdata.productprice}
                      className="Addproduct--number"
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productprice: e.target.value,
                        })
                      }
                      required
                    />
                    <select
                      className="selectinput"
                      name="productunit"
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productunit: e.target.value,
                        })
                      }
                    >
                      <option value="">--CHOOSE--</option>
                      <option value="AZN">AZN</option>
                      <option value="DOLLAR">DOLLAR</option>
                      <option value="EURO">EURO</option>
                    </select>

                    <label>Product Category</label>
                    <select
                      name="productcategory"
                      className="selectinput"
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          productcategory: e.target.value,
                        })
                      }
                    >
                      <option value="">--CHOOSE--</option>
                      <option value="Semi-Abstract">Semi-Abstract</option>
                      <option value="Impressionism">Impressionism</option>
                      <option value="Diamont Art">Diamont Art</option>
                      <option value="Surrealism">Surrealism</option>
                      <option value="Realism">Realism</option>
                      <option value="Modern">Modern</option>
                      <option value="Scenery">Scenery</option>
                      <option value="Minimalism">Minimalism</option>
                      <option value="Portrait">Portrait</option>
                      <option value="Naturmort">Naturmort</option>
                    </select>
                    <select
                      name="producttype"
                      className="selectinput"
                      onChange={(e) =>
                        setaddproductData({
                          ...addproductdata,
                          producttype: e.target.value,
                        })
                      }
                    >
                      <option value="">--CHOOSE--</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Russia">Russia</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                )}
                {step === 2 && (
                  <div className="step">
                    <h2>Step 2: Check your detail</h2>

                    <label>Seller</label>
                    <input
                      type="text"
                      className="Addproduct--text"
                      value={user.fullname}
                      readOnly
                      required
                    />
                    <label>Email</label>
                    <input
                      type="text"
                      value={user.email}
                      className="Addproduct--text"
                      readOnly
                      required
                    />
                    <label>Number</label>
                    <input
                      type="text"
                      className="Addproduct--text"
                      value={`+994${user.number}`}
                      readOnly
                      required
                    />
                  </div>
                )}
                {step === 3 && (
                  <div className="step">
                    <h2>Step 3: Review and Submit</h2>
                    <div style={{ overflow: "scroll", maxHeight: "500px" }}>
                      Photo: {addproductdata?.productimage}
                    </div>
                    <div>Name: {addproductdata?.productname}</div>
                    <div>Description: {addproductdata?.productdescription}</div>
                    <div>
                      Price:{" "}
                      {addproductdata?.productprice +
                        " " +
                        addproductdata?.productunit}
                    </div>
                    <div>Category: {addproductdata?.productcategory}</div>
                    <div>Region: {addproductdata?.producttype}</div>
                    <div>Seller: {addproductdata?.fullname}</div>
                    <div>Email: {addproductdata?.email}</div>
                    <div>Number: {`+994${addproductdata?.number}`}</div>
                  </div>
                )}
                <div className="buttons">
                  {step === 1 && (
                    <button type="button" onClick={handleCancel}>
                      Cancel
                    </button>
                  )}
                  {step > 1 && (
                    <button type="button" onClick={handleBack}>
                      Back
                    </button>
                  )}
                  {step < 3 && (
                    <button type="button" onClick={handleNext}>
                      Next
                    </button>
                  )}
                  {step === 3 && <button type="submit">Submit</button>}
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MultiStepForm;
